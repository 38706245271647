.container_ {
    background: white;
    height: 100vh;
    width: 100vw;
}

.anime-links::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: white;
    transition: width .3s;
}

.anime-linksanime::after {
    width: 100%;
    transition: width .3s;
}

.shadow {
    -webkit-filter: drop-shadow( 5px 5px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    /* Similar syntax to box-shadow */
}

#logo {
    height: 400px;
    width: 400px;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    fill: none;
    stroke-width: 50;
}

path:nth-child(1) {
    stroke: #12232e;
    stroke-dasharray: 15386.603515625;
    stroke-dashoffset: 15386.603515625;
    animation: anime 1s ease forwards, anime-logo1 1s ease forwards 1.2s;
}

path:nth-child(2) {
    stroke: #191345;
    stroke-dasharray: 11150.564453125;
    stroke-dashoffset: 11150.564453125;
    animation: anime 1s ease forwards 0.3s, anime-logo2 1s ease forwards 1.2s;
}

path:nth-child(3) {
    stroke: #0c5885;
    stroke-dasharray: 5488.74072265625;
    stroke-dashoffset: 5488.74072265625;
    animation: anime 1s ease forwards 0.6s, anime-logo3 1s ease forwards 1.2s;
}

path:nth-child(4) {
    stroke: #0c5885;
    stroke-dasharray: 1263.864013671875;
    stroke-dashoffset: 1263.864013671875;
    animation: anime 1s ease forwards 0.9s, anime-logo3 1s ease forwards 1.2s;
}

@keyframes anime {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes anime-logo1 {
    from {
        fill: transparent;
    }
    to {
        fill: #12232e;
    }
}

@keyframes anime-logo2 {
    from {
        fill: transparent;
    }
    to {
        fill: #191345;
    }
}

@keyframes anime-logo3 {
    from {
        fill: transparent;
    }
    to {
        fill: #0c5885;
    }
}