/* @import url('https://fonts.googleapis.com/css?family=Baloo+Tamma+2:400,600,700&display=swap'); */

html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    /* font-family: "Baloo Tamma 2"; */
    color: white;
    padding: 0;
    outline: none;
    overflow-x: hidden;
    overflow-y: hidden;
    /* background-image: url("https://image.freepik.com/free-vector/technology-background-with-abstract-hud_23-2148250643.jpg");
	background-position: center; */
}

#tsparticles {
    height: 100vh;
    background: #232741;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Typewriter_wrapper,
.Typewriter_cursor {
    font-size: 1.75rem;
}


/* ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
    height: 10px
} */


/* background of the scrollbar except button or resizer */


/* ::-webkit-scrollbar-track {
    background-color: #2d2f30;
    background-clip: content-box;
}

::-webkit-scrollbar-track:hover {
    background-color: #2d2f30
} */


/* scrollbar itself */


/* ::-webkit-scrollbar-thumb {
    height: 0px;
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
} */


/* set button(top and bottom of the scrollbar) */


/* ::-webkit-scrollbar-button {
    display: none
} */

#root {
    overflow-x: hidden;
}


/* .nav-link::after {
  position: absolute;
  top:100%;
  content: "";
  height: .1em;
  width:10px;
  background: currentColor;
  z-index: -1;
  transform: scaleX(0);
  transition: transform .5s cubic-bezier(.95, .05, .795, .035);
}

.nav-link:hover::after {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: scaleX(1);
} */

.para {
    font-size: 1.25rem;
}


/* Navbar starts */

.topNav-links {
    margin-right: 10px;
    font-size: 1.5rem;
    font-weight: 500;
}

@keyframes anime {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.animeline {
    opacity: 0;
    position: relative;
    top: 18px;
    background: white;
    width: 0%;
    height: 3px;
}

.awssld__bullets {
    bottom: 50px;
}

.nav-logo {
    width: 50px;
    height: 50px;
    margin-left: 5%;
    border-radius: 10%;
}

@media only screen and (max-width: 600px) {
    .heritage-image {
        height: 270px !important;
    }
}

.contact-icon-div {
    padding-bottom: 10px;
    vertical-align: middle;
}

.contact-right-icon {
    padding-right: 200px;
}

.contact-h5 {
    padding-left: 100px;
}

.contact-anchor {
    text-decoration: none;
    color: white;
}


/* Navbar ends */

.home-carousel {
    width: 100%;
}

.home-div {
    width: 100%;
    padding: 0;
}


/*footer starts*/

#footer {
    background: #1a1245;
    color: white;
    padding-top: 70px;
    padding-bottom: 40px;
    width: 100%;
}

.logoo {
    width: 90px;
    height: 90px;
    margin-left: 60px;
    border-radius: 10%;
}

.logoo1 {
    width: 90px;
    height: 90px;
    margin-left: 30px;
    display: inline;
    border-radius: 10%;
}

.picc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.logoo:hover {
    opacity: 0.8;
}

.logoo1:hover {
    opacity: 0.8;
}

.footer-acm p {
    padding-top: 10px;
    font-size: 20px;
    color: white;
    padding-left: 65px;
    text-align: center;
}

.modal-header {
    color: #1a1245;
}

.modal-body {
    color: #1a1245;
}

@media only screen and (max-width: 600px) {
    .footer-acm {
        font-size: 20px;
        color: white;
        padding-right: 75px;
        text-align: center;
    }
    .footer-acm p {
        font-size: 1rem;
        /* text-align: center; */
        /* margin-left: 10px; */
    }
    .address {
        font-size: 15px !important;
    }
    .footer-h3 {
        font-size: 1.5rem;
    }
    #footer {
        background: #1a1245;
        color: white;
        padding-top: 40px;
        padding-bottom: 30px;
        width: 100%;
    }
}

@media only screen and (max-width: 320px) {
    .footer-acm {
        padding-right: 70px;
    }
}

.footer-h3 {
    text-align: center;
}

.location2 {
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
}

.foot_icon img {
    width: 40px;
    height: 40px;
    border-radius: 10%;
}

.foot_icon img:hover {
    opacity: 0.5;
}

.icons_uni {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.fa-heart {
    color: red
}

.foot {
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
}

@media only screen and (max-width:741px) {
    .para {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width:600px) {
    .logoo {
        width: 60px;
        height: 60px;
        margin-left: 68px;
    }
    .logoo1 {
        width: 60px;
        height: 60px;
    }
    .foot_icon img {
        width: 28px;
        height: 28px;
        border-radius: 10%;
    }
    .events_a {
        font-size: 18px;
        color: #377293;
        padding-top: 25%;
    }
    .events_date {
        margin-bottom: 3%;
    }
}

@media only screen and (max-width:460px) {
    #footer {
        padding-top: 30px;
        padding-bottom: 10px;
        width: 100%;
    }
    .foot {
        font-size: 15px !important;
    }
    .footer-h3 {
        padding-top: 8px;
        margin-right: 25px;
    }
    .icons_uni {
        padding-top: 4px;
    }
    .picc {
        display: block;
        padding-left: 4px;
    }
    .footer-acm p {
        margin-bottom: 3px;
        margin-left: 65px;
        padding-left: 4px;
        font-size: 15px;
    }
    .logoo {
        width: 60px;
        height: 60px;
        margin-left: 65px;
    }
    .logoo1 {
        width: 60px;
        height: 60px;
    }
    .foot_icon img {
        width: 30px;
        height: 30px;
        border-radius: 10%;
    }
    .footer-h3 {
        font-size: 25px;
        margin: 4px;
    }
}


/*footer ends*/


/*Sponsers starts*/

#sponsors {
    margin-bottom: 50px;
}

.team_head {
    color: #1a1245;
    font-size: 50px;
    font-weight: 700;
}

.head_spons {
    text-align: center;
    color: #1a1245;
    font-size: 40px;
    font-weight: 700;
    /* margin-bottom: 36px; */
    /* margin-top: 20px; */
    margin-right: 2%;
}

@media only screen and (max-width:360px) {
    .head_spons {
        font-size: 20px;
        margin-left: 20px;
    }
    #sponsors {
        margin-left: -10%;
    }
}


/*Sponsers ends*/


/* Events starts */

#events {
    margin: 20px;
    margin-bottom: 40px;
}

.table {
    margin-right: 20px;
    margin-top: 30px;
}

.event_head {
    text-align: center;
    font-size: 50px;
    color: #1a1245;
    font-weight: bold;
}

.th_e {
    text-align: center;
    font-size: 20px;
}

.socialbtn {
    outline: none;
    border: 0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: white;
    background: #377293;
}

.socialbtn:hover {
    color: #377293;
    background: white;
    border: 2px #377293 solid;
}

table.table a {
    margin: 0;
    color: #377293;
    font-weight: bold;
}

.events_a {
    font-size: 18px;
    color: #377293;
}

.events_a:hover {
    font-size: 18px;
    color: blue;
    font-weight: bold;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}

.table tr:nth-child(even) {
    background-color: #eee;
}

.table tr:nth-child(odd) {
    background-color: #fff;
}

.table th {
    color: white;
    background-color: #1a1245;
}


/* Events ends */


/* EventsDetails starts */

.eventdetails {
    margin: 25px;
}

#new-img {
    width: 60%;
    height: 60%;
    margin-left: 22%;
    margin-top: 10px;
    border-radius: 2%;
    /* border: 3px solid gray; */
}

.txt_body h5 {
    color: brown;
    margin-bottom: 5px;
}

.txt_body h1 {
    margin-bottom: 5px;
}

@media only screen and (max-width: 360px) {
    #new-img {
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-top: 0;
    }
    .txt_body h1 {
        font-size: 24px;
    }
    .txt_body h5 {
        font-size: 18px;
        color: brown;
    }
    .txt_body .pre {
        font-size: 16px;
    }
    .backToEvents {
        margin: 13px;
        margin-left: 5px;
        font-size: 12px;
    }
    .backToEvents.btn {
        padding: 4px;
    }
}

.backToEvents {
    margin: 15px;
    margin-left: 5px;
    font-size: 20px;
}

.txt_body {
    text-align: center;
    margin-top: 15px;
}

.pre {
    font-family: inherit;
    white-space: pre-line;
    font-size: 19px;
}


/* EventsDetails ends */


/* contact card starts */

.contact-social {
    text-align: left;
    margin-left: 25%;
    margin-right: 18%;
    padding-left: 10%;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    font-family: 'Roboto', sans-serif;
    color: #377293;
}

@media only screen and (min-width: 1000px) {
    .socialbtn {
        outline: none;
        border: 0;
        height: 55px;
        width: 55px;
        border-radius: 50%;
        color: white;
        background: #377293;
    }
    .contact-icon-text {
        padding-left: 5%;
        margin-bottom: 5%;
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        padding-right: 5%;
    }
    .contact-social {
        text-align: left;
        margin-left: 23%;
        margin-right: 5%;
        padding-left: 10%;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        font-family: 'Roboto', sans-serif;
        color: #377293;
    }
    .social-handles {
        padding-bottom: 9%;
    }
}

.reach-us {
    padding-left: 19%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.social-handles {
    padding-bottom: 12%;
}

.contact-card {
    text-align: center;
    height: 7%;
}

.contact-icon-text {
    padding-left: 20%;
    margin-bottom: 10%;
    font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 600px) {
    .contact-social {
        text-align: left;
        margin-left: 5%;
        margin-right: 5%;
        padding-left: 10%;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        font-family: 'Roboto', sans-serif;
        color: #377293;
    }
    .mail-form {
        width: 600px;
    }
    .form-input {
        width: 600px;
    }
    .contact-card {
        text-align: center;
        height: 80%;
    }
    .about-us-image {
        height: 10%;
    }
}

@media only screen and (max-width: 600px) {
    .formbtn {
        margin-left: 12%;
    }
}

@media only screen and (min-width: 1000px) {
    .formbtn {
        margin-left: 32%;
    }
}

.map-row {
    text-align: center;
}

.mail-form-text {
    position: "absolute";
    top: -20;
    width: 400;
    z-index: 3;
    left: 100;
}

.link-anchor {
    text-decoration: none;
    color: #377293;
}


/* contact card ends */


/* event carousel starts */

.container {
    margin: 10% auto;
    padding: 80px 0;
    text-align: center;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    background-color: #f5f5f5;
}

.slick .slick-next:before,
.slick .slick-prev:before {
    font-size: 2rem;
    color: rgb(206, 192, 192);
}

.slick .slick-next:before {
    margin-right: 40px;
}

.slick {
    margin: 30px auto;
    margin-left: 80px;
    padding: 0px 20px 20px 20px;
    width: 500px;
    background: #fff;
    color: black;
    text-align: center;
}

.slick .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}

.slick-h2 {
    font-family: 'Piedra', cursive;
    padding-top: 10px;
    border-color: black;
    border-bottom: solid;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.slick-h1 {
    font-family: 'Piedra', cursive;
    font-size: 3rem;
    padding-bottom: 10px;
}

.card .card-body .card-text {
    color: brown;
    font-weight: bold;
}

.slick-date {
    font-family: 'Roboto', sans-serif;
    padding-bottom: 5px;
}

.slick-show {
    text-decoration: none;
    color: blue;
}

.slick-image {
    width: 300px;
    height: 200px;
    margin-left: 35%;
    margin-bottom: 5%;
}

.event-card {
    margin-top: 15px;
}

*:focus {
    outline: 0;
    /* border: none; */
}

@media only screen and (max-width: 600px) {
    .slick-image {
        width: 300px;
        height: 200px;
        margin-left: 10%;
        margin-bottom: 5%;
    }
}

.slick-div {
    text-align: center;
}

@media only screen and (max-width: 415px) {
    .slick .slick-next:before,
    .slick .slick-prev:before {
        font-size: 3rem;
    }
    .slick .slick-next::before {
        margin-right: 25px;
    }
    .slick .slick-prev:before {
        margin-left: -2px;
    }
    .col-8 {
        width: 120%;
    }
}


/* event carousel ends */

@media only screen and (max-width: 600px) {
    .event-card {
        margin-left: 3%;
        margin-right: 5%;
        padding-right: 5%;
    }
    .home-nav {
        width: 600px;
    }
    .card-image {
        width: 200px;
        height: 200px;
    }
}

.card-image {
    width: 400px;
    height: 200px;
    object-fit: cover;
}

.card-title {
    color: black;
}

.slick .slick-prev:before {
    z-index: 10001;
    /* margin-left: -150px; */
    position: absolute;
    left: -35px;
    /* margin-bottom: 40px; */
    top: -80px;
}

.slick .slick-next:before {
    /* margin-left: 50px; */
    position: absolute;
    z-index: 10001;
    right: 20;
    right: -25px;
    top: -80px;
}

@media only screen and (max-width: 900px) {
    .slick .slick-prev:before {
        left: -45px;
    }
    .slick .slick-next:before {
        right: -45px;
    }
}

.slick .slick-next:before,
.slick .slick-prev:before {
    border: 5px solid #1A1245;
    padding: 8px;
    border-radius: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 2rem;
    color: #1A1245;
}

.slick-track {
    height: 500px;
}

@media only screen and (max-width: 415px) {
    .slick {
        margin: 10px 0 0 55px;
        /* width: 1200%; */
    }
    .slick .slick-prev:before {
        left: 3px;
    }
    .slick .slick-next:before,
    .slick .slick-prev:before {
        border: 3px solid #1A1245;
        padding: 4px;
        border-radius: 5px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1rem;
        color: #1A1245;
    }
    .slick-track {
        height: 530px;
    }
    .card-image {
        width: 180px;
        height: 180px;
        object-fit: cover;
    }
    .card-title {
        color: black;
        font-size: 19px;
        font-weight: 700;
    }
}

.loading-events {
    margin-left: 47%;
}

.spinner-border {
    position: absolute;
    top: 45%;
    left: 45%;
}

.slick-slide img {
    width: 100%;
}

.navbar.navbar-light .navbar-toggler-icon .hamburger {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

@media only screen and (max-width: 600px) {
    .loading-events {
        margin-left: 41%;
    }
}


/* ABOUT */

.about {
    border-radius: 1%;
    position: relative;
    /* background: rgb(224, 221, 221); */
    overflow: hidden;
}

.about img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    opacity: 0.5;
}

@media only screen and (max-width: 1500px) {
    .about img {
        height: 100%;
    }
}


/* ABOUT END */

@media only screen and (max-width: 900px) {
    .homeCarosel {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 561px) {
    .spons_img {
        width: 100%;
    }
    .spons_img2 {
        width: 100%;
    }
    .mail-card {
        margin-left: 5%;
        margin-right: 5%;
    }
    .md-form .prefix~input,
    .md-form .prefix~textarea {
        width: 40%;
    }
    md-form .prefix~input,
    .md-form .prefix~textarea {
        width: 40%;
    }
    .head_contact {
        margin-left: 0;
    }
    .spons_card {
        padding-right: 9%;
    }
}

@media only screen and (max-width: 341px) {
    .md-form .prefix~input,
    .md-form .prefix~textarea {
        width: 30%;
    }
    md-form .prefix~input,
    .md-form .prefix~textarea {
        width: 30%;
    }
    .contact-social {
        padding-left: 3%;
    }
    .head_spons {
        margin-left: 0;
    }
}

.about-ism {
    color: #1a1245;
    font-weight: bold;
    /* font-family: 'Roboto', sans-serif; */
}

.about-acm {
    color: #1a1245;
    font-weight: bold;
    /* font-family: 'Roboto', sans-serif; */
}

.toggler {
    font-size: 2rem;
}

.hamburger {
    color: red;
}

#responsive-navbar-nav {
    color: white !important;
}

.explore {
    margin-top: -110px;
    margin-left: 0;
}

@media only screen and (max-width: 600px) {
    .explore {
        margin-top: -150px;
        margin-left: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 415px) {
    .abouts {
        font-size: 16px !important;
        color: #1a1245;
        font-weight: bold;
    }
}

@media only screen and (max-width: 500px) {
    .join {
        display: none !important;
    }
}

.form-input {
    width: 85%;
    margin-left: 20px;
    margin-top: 8%;
}

@media only screen and (max-width: 600px) {
    .form-input {
        width: 40%;
        margin-left: 20px;
        margin-top: 7%;
    }
    .recent-card {
        width: 180px;
        height: 250px;
    }
}

video {
    height: 100vh;
    width: 100%;
    object-fit: fill;
    /* position: absolute; */
}

@media only screen and (max-width:700px) {
    video {
        height: 75vh;
        width: 100%;
        object-fit: fill;
    }
}

@media only screen and (max-width:450px) {
    video {
        /* margin-top: 80px; */
        height: 41vh;
        width: 100%;
        object-fit: fill;
    }
}

@media only screen and (max-width: 415px) {
    .abouts {
        font-size: 18px;
        color: #1a1245;
        font-weight: bold;
    }
}


/* recent events starts */

.recent-events {
    margin-top: 100px;
}

.recent-card {
    text-align: center;
}

.recent-hr {
    width: 50%;
}


/* recent events ends */

.icpcc {
    margin: 100px 0;
}

.gs {
    margin-top: 40px;
}

@media only screen and (max-width: 485px) {
    .achieve {
        font-size: 35px;
    }
    .icpcc {
        margin: 0;
    }
    .gs {
        margin-top: 0;
    }
}

.join {
    margin: 15px 0;
    margin-left: 46%;
}

@media only screen and (min-width: 1400px) {
    .bene {
        background: url("./components/benefits-02.jpg");
        width: 900px;
        height: 600px;
        background-size: 1000px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 17%;
    }
    .join-acm {
        margin-left: 44%;
        margin-top: 1%;
        margin-bottom: 2%;
    }
    .h1-responsive {
        margin-right: 4%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .bene {
        background: url("./components/benefits-02.jpg");
        width: 900px;
        height: 600px;
        background-size: 1000px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 13%;
    }
    .join-acm {
        margin-left: 44%;
        margin-top: 1%;
        margin-bottom: 2%;
    }
    .h1-responsive {
        margin-right: 4%;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .bene {
        background: url("./components/benefits-02.jpg");
        width: 800px;
        height: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 10%;
    }
    .join-acm {
        margin-left: 44%;
        margin-top: 1%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
    .bene {
        background: url("./components/benefits-02.jpg");
        width: 700px;
        height: 450px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 10%;
    }
    .join-acm {
        margin-left: 44%;
        margin-top: 2%;
        margin-bottom: 3%;
    }
}

.typewriter-col {
    background: #00B4DB;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0083B0, #00B4DB);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media only screen and (max-width: 500px) {
    .join-anchor {
        position: absolute;
        top: 55%;
        left: 35%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
    .bene {
        background: url("./components/benefits-02.jpg");
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bene-div {
        width: 800px;
        height: 550px;
    }
    .join-acm {
        margin-left: 38%;
        margin-top: 2%;
        margin-bottom: 4%;
    }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
    .bene {
        background: url("./components/benefits-01.png");
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bene-div {
        width: 400px;
        height: 600px;
    }
    .join-acm {
        margin-left: 35%;
        margin-top: 3%;
        margin-bottom: 4%;
    }
}

@media only screen and (min-width: 361px) and (max-width: 400px) {
    .bene {
        background: url("./components/benefits-01.png");
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bene-div {
        width: 400px;
        height: 600px;
    }
    .join-acm {
        margin-left: 28%;
        margin-top: 3%;
        margin-bottom: 4%;
    }
}

@media only screen and (max-width: 360px) {
    .bene {
        background: url("./components/benefits-01.png");
        height: 80%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bene-div {
        width: 360px;
        height: 400px;
    }
    .join-acm {
        margin-left: 28%;
        margin-top: 4%;
        margin-bottom: 4%;
    }
}